<template>
	<div class="design" v-title="'设计师——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
		    <img class="bran" src="../image/design/header_pc.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/design/header_mob.jpg"
				alt=""
			/>
		</div>
        <div class="content f-y-s-c">
			<div class="filter" v-if="showAll">
                <CaseCategory 
                    addressTitle="所在门店"
                    :showHouType="false"
                    :showAddress="false"
                    :showAreas="false"
                    :showHouStyle="false"
                    @callback="callback"
                    >
                </CaseCategory>
            </div>
            <div v-if="designList.length" class="list">
                <div
                    v-for="(item,index) in designList"
                    :key="index" 
                    @mouseenter="showDiaInd=index" @mouseleave="showDiaInd=-1"
                    class="list-item"
                    :class="showDiaInd==index ? 'show-item':''"
                    @click="navToDetail(item.id)"
                    >
                    <div class="img-box">
                        <img class="item-img" :src="item.headerImgUrl" alt="">
                    </div>
                    
                    
                    <div class="box1 f-x-b-c">
                        <div class="text1 f-x-c-c">{{ item.showRoomNo && formartInfo('store',item.showRoomNo) }}</div>
                        <div @click.stop="openDialog" class="text2 f-x-c-c">找TA设计</div>
                    </div>
                    <div class="box2 f-x-c-c">
                        最近三十天已被预约 {{item.countView}} 人次
                    </div>
                    <div class="bottom f-x-b-c">
                        <div 
                            v-if="curDesCaseList.length"
                            class="dialog f-x-b-c" 
                            :class="showDiaInd==index ? 'show-dialog':''"
                            >
                            <div 
                                v-for="(it,idx) in curDesCaseList"
                                :key="idx"
                                class="img-box2">
                                <img 
                                    
                                    @click.stop="navToCaseDetail(it.id)"
                                    class="img2" 
                                    :src="it.coverUrl" 
                                    alt="">
                            </div>
                           
                        </div>
                        <div class="info f-y-c-s">
                            <div class="name f-x-s-c">
                                <span class="text3">{{item.name}}</span>
                                <span class="text4">{{item.ranks && formartInfo('ranks',item.ranks)}}</span>
                            </div>
                            <span class="text5">从业经验：{{ item.workingLife }} 年</span>
                            <span class="text6">设计理念：{{ item.designConcept}}</span>
                            <span class="text6">擅长风格：{{ item.designStyle }}</span>
                        </div>
                    </div>
                    <div class="bottom2 f-y-b-c">
                        
                        <div class="info f-y-c-s">
                            <div class="name f-x-s-c">
                                <span class="text3">{{item.name}}</span>
                                <span class="text4">{{item.ranks && formartInfo('ranks',item.ranks)}}</span>
                            </div>
                            <span class="text5">从业经验：{{ item.workingLife }} 年</span>
                            <span class="text6">设计理念：{{ item.designConcept}}</span>
                            <span class="text6">擅长风格：{{ item.designStyle }}</span>
                        </div>
                        <div 
                            v-if="item.desCaseList && item.desCaseList.length"
                            class="dialog " 
                            >
                           <img 
                                v-for="(it,idx) in item.desCaseList"
                                :key="idx"
                                @click.stop="navToCaseDetail(it.id)"
                                class="img2" 
                                :src="it.coverUrl" 
                                alt="">
                        </div>
                    </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据" class="empty" ></el-empty>
            <Pagination
                :page-size="params.size"
                :current-page="params.current"
                :total="total"
                @current-change="changeCurrent"
            ></Pagination>
		</div>

		<!-- 底部 -->
		<Footer></Footer>
        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>

        <DialogForm ref="refDialogForm" adUnitCode="uiop"></DialogForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import HouseInfo from '../components/HouseInfo.vue'
import CaseCategory from '../components/CaseCategory.vue'
import { useSalesRoomDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'
import {codeToText} from 'element-china-area-data'
import { getDesignList } from '@/api/design.js'
import { getCaseData } from '@/api/case.js'
import {mapState} from 'vuex'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
        CaseCategory,
        HouseInfo
	},
	data() {
		return {
            codeToText,
            showDiaInd:-1,
			designList: [],
            desList:[],
            total:0,
            params:{
                size:12,
                current:1, 
                showRoomNo:'',
                ranks:'',
                designStyle:'',
            },
            ranksList:[],
            storeList:[],
            curDesCaseList:[],
		}
	},
    computed:{
        ...mapState(['curCityInfo']),
        showAll(){
            console.log("this.curCityInfo",this.curCityInfo);
            if(this.curCityInfo.value=='00') return true
            else return false
        }
    },
    async mounted(){
        this.getDesignList()
        this.ranksList = await useDesRankDict()
        this.storeList = await useSalesRoomDict()
    },
    watch:{
        async showDiaInd(newVal){
            try{
                if(newVal!='-1'){
                    const designerId = this.designList[newVal].id
                    const res = await getCaseData({size:2,current:1,designerId})
                    if(res.code==0){
                        this.curDesCaseList = res.data.records
                    }else this.curDesCaseList = []
                }
            }catch(err){
                console.log(err);
                return this.curDesCaseList = []
            }
            
        },
    },
	methods: {
        navToDetail(id){
            this.$router.push({
                path:'/design/detail',
                query:{
                    id
                }
            })
        },
        navToCaseDetail(id){
            this.$router.push({
                path:'/investor/detail',
                query:{
                    id
                }
            })
        },
        openDialog(){
            this.$refs.refDialogForm.showDialog('design')
        },
        changeCurrent(data){
            this.params.current = data
            this.getDesignList()
        },
        async getDesignList(){
            try{
                const res = await getDesignList(this.params)
                if(res.code==0){
                    const promiseList = []
                    this.designList = res.data.records
                    res.data.records.map(item=>{
                        const promise = getCaseData({size:3,current:1,designerId:item.id})
                        promiseList.push(promise)
                    })
                    Promise.all(promiseList).then(val=>{
                        val.map((it,index)=>{
                            this.$set(this.designList[index],'desCaseList',it.data.records)
                            // this.designList[index].desCaseList = it.data.records
                        })
                    })
                    this.total = res.data.total
                }
            }catch(err){
                console.log(err);
            }
        },
        formartInfo(type,data){
            if(type=='ranks'){
                const res = this.ranksList.find(item=>item.value==data)
                if(res) return res.label
                else return data
            }
            if(type=='store'){
                const res = this.storeList.find(item=>item.showRoomNo==data)
                if(res) return res.showRoomName
                else return data
            }
        },
        callback(data){
            const {store,houseArea,designStyle,houseType,ranks} = data
            if(store=='-1') delete this.params.showRoomNo
            else  this.params.showRoomNo = store

            if(houseArea=='-1') delete this.params.houseArea
            else  this.params.houseArea = houseArea

            if(ranks=='-1') delete this.params.ranks
            else  this.params.ranks = ranks
            
            if(designStyle=='-1') delete this.params.designStyle
            else this.params.designStyle = designStyle

            if(houseType=='-1') delete this.params.houseType
            else this.params.houseType = houseType
            this.params.current = 1
            this.getDesignList()
        }

    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .design {
        overflow-x: hidden ;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 160px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            padding-bottom: 30px;
			.filter{
                width: 100%;
            }
            .list{
                margin-top: 25px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 10px;
                box-sizing: border-box;
                .show-item{
                    border-radius: 9px 9px 0 0 !important;
                }
                .list-item{
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 9px;
                    background: #fff;
                    margin-bottom: 20px;
                    position: relative;
                    width: 100%;
                    cursor: pointer;
                    .img-box{
                        width: 100%;
                        height: 280px;
                        overflow: hidden;
                    }
                    .item-img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                        border-radius: 9px 9px 0 0 ;
                    }
                    .box1{
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        width: calc(100% - 10px) ;
                        box-sizing: border-box;
                        .text1{
                            width: 60px;
                            height: 25px;
                            background: rgba(0, 0, 0, 0.5);
                            border-radius: 18px;

                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 25px;
                        }
                        .text2:hover{
                            background: #f6900c;
                        }
                        .text2{
                            
                            width: 76px;
                            height: 25px;
                            background: #753CBE;
                            border-radius: 15px  0px 0px 15px;

                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 25px;
                        }
                    }
                    .box2{
                        position: absolute;
                        top: 250px;
                        left: 0;
                        width: 100%;
                        height: 30px;
                        background: #000000;
                        border-radius: 9px 9px 0px 0px;
                        background: rgba(0,0,0,0.2);
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #FFFFFF;
                        line-height: 12px;
                    }
                    .bottom{
                        display: none;
                    }
                    .bottom2{
                        width: 100%;
                        min-height: 100px;
                        padding: 20px 0 10px 0;
                        box-sizing: border-box;
                        position: relative;
                        .show-dialog{
                            display: flex !important;
                        }
                        .dialog{
                            display: grid;
                            grid-template-columns: repeat(3,1fr);
                            grid-template-rows: 1;
                            gap: 20px 15px;
                            margin-top: 20px;
                            padding: 10px 10px;
                            width: 100%;
                            height: 100px;
                            background: #FFFFFF;
                            border-radius:0 0  9px 9px;
                            box-sizing: border-box;
                            border-top: 1px solid #EBEBEB;
                            z-index: 9;
                            .img2{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        
                        .info{
                            padding: 0 10px;
                            cursor: pointer;
                            width: 100%;
                            box-sizing: border-box;
                            .name{
                                width: 100%;
                                .text3{
                                    
                                    font-weight: bold;
                                    font-size: 20px;
                                    color: #241934;
                                    line-height: 20px;
                                    margin-right: 10px;
                                }
                                .text4{
                                    
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #444444;
                                    line-height: 13px;
                                }
                            }
                            .text5{
                                
                                font-weight: 300;
                                font-size: 12px;
                                color: #444444;
                                line-height: 12px;
                                margin-top: 15px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                            .text6{
                                
                                font-weight: 300;
                                font-size: 12px;
                                color: #444444;
                                line-height: 12px;
                                margin-top: 8px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                        }
                       
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
		}
    }
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.design {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 2% 70px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.filter{
                width: 100%;
                margin-top: 20px;
            }
            .list{
                margin-top: 50px;
                width: 100%;
                display: grid;
                justify-content: space-between;
                // grid-template-columns: repeat(auto-fill,486px);
                // gap: 0 5px;
                grid-template-columns: repeat(auto-fill,32%);
                .show-item{
                    border-radius: 18px 18px 0 0 !important;
                }
                .list-item:hover{
                    .item-img{
                        transform: scale(1.2);
                    }
                    .box1{
                        .text2{
                            transform: translateX(-146px);
                        }
                    }
                    
                }
                .list-item{
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 18px;
                    background: #fff;
                    margin-bottom: 50px;
                    position: relative;
                    
                    cursor: pointer;
                    .img-box{
                        width: 100%;
                        height: 486px;
                        border-radius: 18px 18px 0 0 ;
                        overflow: hidden;
                        .item-img{
                            transition: all .6s;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 18px 18px 0 0 ;
                        }
                    }
                    
                    .box1{
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        width: calc(100% - 20px) ;
                        box-sizing: border-box;
                        overflow: hidden;
                        .text1{
                            
                            width: 120px;
                            height: 56px;
                            // background: #000000;
                            background: rgba(0, 0, 0, 0.5);
                            border-radius: 28px;
                            // opacity: 0.5;

                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                        .text2:hover{
                            background: #f6900c;
                        }
                        .text2{
                            transition: transform .6s;
                            position: absolute;
                            right: -146px;
                            width: 146px;
                            overflow: hidden;
                            white-space: nowrap;
                            height: 56px;
                            background: #753CBE;
                            border-radius: 30px  0px 0px 30px;

                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                    }
                    .box2{
                        position: absolute;
                        top: 436px;
                        left: 0;
                        width: 100%;
                        height: 50px;
                        background: #000000;
                        border-radius: 18px 18px 0px 0px;
                        background: rgba(0,0,0,0.2);
                        
                        font-weight: 400;
                        font-size: 24px;
                        color: #FFFFFF;
                        line-height: 24px;
                    }
                    .bottom2{
                        display: none;
                    }
                    .bottom{
                        width: 100%;
                        height: 254px;
                        padding: 35px 24px;
                        box-sizing: border-box;
                        position: relative;
                        .show-dialog{
                            display: flex !important;
                        }
                        .dialog{
                            display: none;
                            position: absolute;
                            bottom: -210px;
                            left: 0;
                            padding: 30px 24px;
                            width: 100%;
                            height: 210px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
                            border-radius:0 0 18px 18px;
                            box-sizing: border-box;
                            border-top: 1px solid #EBEBEB;
                            z-index: 9;
                            .img-box2:hover{
                                .img2{
                                    transform: scale(1.2);
                                }
                            }
                            .img-box2{
                                width: 210px;
                                height: 150px;
                                overflow: hidden;
                                .img2{
                                    transition: all .6s;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            
                        }
                        
                        .info{
                            cursor: pointer;
                            width: 100%;
                            .name{
                                width: 100%;
                                .text3{
                                    
                                    font-weight: bold;
                                    font-size: 48px;
                                    color: #241934;
                                    line-height: 48px;
                                    margin-right: 20px;
                                }
                                .text4{
                                    
                                    font-weight: 400;
                                    font-size: 28px;
                                    color: #444444;
                                    line-height: 28px;
                                }
                            }
                            .text5{
                                
                                font-weight: 300;
                                font-size: 24px;
                                color: #444444;
                                line-height: 24px;
                                margin-top: 37px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                            .text6{
                                
                                font-weight: 300;
                                font-size: 24px;
                                color: #444444;
                                line-height: 24px;
                                margin-top: 10px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                        }
                        .msg{
                            height: 60px;
                            background: #753CBE;
                            padding: 5px 11px 5px 13px;
                            background: #753CBE;
                            border-radius: 30px 0 0 30px;
                            box-sizing: border-box;
                            
                            font-weight: 400;
                            color: #FFFFFF;
                            font-size: 28px;
                        }
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
		}
	}
}
@media screen and (min-width: 1360px) {
	.design {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 180px 70px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.filter{
                width: 100%;
                margin-top: 20px;
            }
            .list{
                margin-top: 50px;
                width: 100%;
                display: grid;
                justify-content: space-between;
                // grid-template-columns: repeat(auto-fill,486px);
                // gap: 0 5px;
                grid-template-columns: repeat(auto-fill,32%);
                .show-item{
                    border-radius: 18px 18px 0 0 !important;
                }
                .list-item:hover{
                    .item-img{
                        transform: scale(1.2);
                    }
                    .box1{
                        .text2{
                            transform: translateX(-146px);
                        }
                    }
                    
                }
                .list-item{
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 18px;
                    background: #fff;
                    margin-bottom: 50px;
                    position: relative;
                    
                    cursor: pointer;
                    .img-box{
                        width: 100%;
                        height: 486px;
                        border-radius: 18px 18px 0 0 ;
                        overflow: hidden;
                        .item-img{
                            transition: all .6s;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 18px 18px 0 0 ;
                        }
                    }
                    
                    .box1{
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        width: calc(100% - 20px) ;
                        box-sizing: border-box;
                        overflow: hidden;
                        .text1{
                            
                            width: 120px;
                            height: 56px;
                            // background: #000000;
                            background: rgba(0, 0, 0, 0.5);
                            border-radius: 28px;
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                        .text2:hover{
                            background: #f6900c;
                        }
                        .text2{
                            transition: transform .6s;
                            position: absolute;
                            right: -146px;
                            width: 146px;
                            overflow: hidden;
                            white-space: nowrap;
                            height: 56px;
                            background: #753CBE;
                            border-radius: 30px  0px 0px 30px;

                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                    }
                    .box2{
                        position: absolute;
                        top: 436px;
                        left: 0;
                        width: 100%;
                        height: 50px;
                        background: #000000;
                        border-radius: 18px 18px 0px 0px;
                        background: rgba(0,0,0,0.2);
                        
                        font-weight: 400;
                        font-size: 24px;
                        color: #FFFFFF;
                        line-height: 24px;
                    }
                    .bottom2{
                        display: none;
                    }
                    .bottom{
                        width: 100%;
                        height: 254px;
                        padding: 35px 24px;
                        box-sizing: border-box;
                        position: relative;
                        .show-dialog{
                            display: flex !important;
                        }
                        .dialog{
                            display: none;
                            position: absolute;
                            bottom: -210px;
                            left: 0;
                            padding: 30px 24px;
                            width: 100%;
                            height: 210px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.2);
                            border-radius:0 0 18px 18px;
                            box-sizing: border-box;
                            border-top: 1px solid #EBEBEB;
                            z-index: 9;
                            .img-box2:hover{
                                .img2{
                                    transform: scale(1.2);
                                }
                            }
                            .img-box2{
                                width: 210px;
                                height: 150px;
                                overflow: hidden;
                                .img2{
                                    transition: all .6s;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            
                        }
                        
                        .info{
                            cursor: pointer;
                            width: 100%;
                            .name{
                                width: 100%;
                                .text3{
                                    
                                    font-weight: bold;
                                    font-size: 48px;
                                    color: #241934;
                                    line-height: 48px;
                                    margin-right: 20px;
                                }
                                .text4{
                                    
                                    font-weight: 400;
                                    font-size: 28px;
                                    color: #444444;
                                    line-height: 28px;
                                }
                            }
                            .text5{
                                
                                font-weight: 300;
                                font-size: 24px;
                                color: #444444;
                                line-height: 24px;
                                margin-top: 37px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                            .text6{
                                
                                font-weight: 300;
                                font-size: 24px;
                                color: #444444;
                                line-height: 24px;
                                margin-top: 10px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                        }
                        .msg{
                            height: 60px;
                            background: #753CBE;
                            padding: 5px 11px 5px 13px;
                            background: #753CBE;
                            border-radius: 30px 0 0 30px;
                            box-sizing: border-box;
                            
                            font-weight: 400;
                            color: #FFFFFF;
                            font-size: 28px;
                        }
                    }
                }
            }
            .empty{
                height: 400px;
                width: 100%;
            }
		}
	}
}
</style>
